<template v-if="showPage">
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("menu.modules") }}
        </router-link>
    </div>
    <custom-table
        :title="sprintf($t('pages.module.lecture.titlePattern'), [productTranslate.title ?? ''])"
        :subTitle="$t('pages.module.lecture.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:title="{ row: record }">
            {{ resolveDatum(record.translations, $root.defaultLanguage.id, 'language_id').title }}
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="'/module/lecture/save/' + record.id + '?productID=' + productID" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </router-link>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.module.lecture.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.lecture.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("pages.module.lecture.cols.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("pages.module.lecture.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: [],
            product: {},
            productID: undefined,
            showPage: false
        }
    },
    computed: {
        table() {
            return this.$store.state.module.lecture.table;
        },
        productTranslate() {
            return this.resolveDatum(this.product.translations, this.$root.defaultLanguage.id, 'language_id');
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.lecture.title"), [this.$t("menu.moduleManagement")]);
        if (this.$route.query.productID) {
            this.productID = parseInt(this.$route.query.productID);

            if (!this.productID > 0) {
                this.$router.push('/module');
            }

            this.showPage = true;

            this.$store.dispatch('module/lecture/get', {
                page: {},
                filterData: {
                    product_id: this.productID,
                    sort: 'created_at:desc'
                }
            });

            this.loadProduct();
        } else {
            this.$router.push('/module/lecture/select-product');
        }
    },
    methods: {
        loadProduct(productID = this.productID) {
            this.axios.get(this.endpoints['ecommerce_product'] + '/' + productID).then((response) => {
                let data = response.data.data;
                this.product = data;
            })
        },
        deleteRecord(id) {
            this.$store.dispatch("module/lecture/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/module/lecture/save",
                        query: {productID: this.productID}
                    });
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("module/lecture/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/lecture/get", {
                page: pagination,
                filterData: Object.assign({
                    product_id: this.productID,
                    sort: 'created_at:desc'
                }, filterData)
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
    }
}
</script>

<style>
.disabled-upload .el-upload.el-upload--picture-card {
    display: none;
}
</style>